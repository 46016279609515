import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "./landingpage.css";
import Background from "../assets/pcore_image.png";
import { useOktaAuth } from "@okta/okta-react";
import MUIDataTable from "mui-datatables";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    centeredHeader: {
      '& span': {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '15px',
        fontWeight: "600"

      },
    },
    centeredrow: {
      '& span': {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '15px',
        fontWeight: "400"

      },
    },
  })
);
//const DataCl = testdata.data_classification;
function Identifers(test){
    var inditm='';
    {test.map(itm => {
        if (inditm == '') 
        {
            inditm=itm;
        } else {
            inditm=inditm+', '+itm;
            }
        })};
    return inditm
}
const ExpandableRowTable = props => {
  //const data = DgPage('get-pii-summary-table'); 
    const Url = process.env.BASE_URL;
    const Svc = process.env.SVC_ACCT;
    const API_KEY = process.env.RUM_API;
    const params = new URLSearchParams([["email", Email]]);
    const { oktaAuth } = useOktaAuth();
    const [DataCl, setDataCl] = useState([]);
    const classes = useStyles();
    var Email = oktaAuth.authStateManager._authState.idToken.claims.email;
    useEffect(() => {
        axios
          .get(
            Url + `get-pii-data`,
            {
              headers: {
                "rum-svc-acct": Svc,
                "rum-api-key": API_KEY,
              },
            }
          )
          .then(data => {
            const data1 =
              data.data['data_classification'] !== null
                ? data.data['data_classification']
                : "Currently No Identifers.";
                setDataCl(data1);
          })
          .catch(err => console.log(err));
      }, []);
  console.log('insideexp');
  console.log(DataCl);
  const roleData1 =[];
  {DataCl && DataCl.map(data => { roleData1.push([data.database_name,data.table_name,'Snowflake',Identifers(data.identifiers),data.pii_count,data.subfields])})};
  console.log(roleData1); 
  const columns = [ 
    {
      name: "Database Name",
      options: {        setCellHeaderProps: () => ({className: classes.centeredHeader, }),     },
      align: 'center',
    },
    {
      name: "Table Name",
      options: {        setCellHeaderProps: () => ({className: classes.centeredHeader, }),     },
      align: 'center',
    },
    {
      name: "Data Source",
      options: {        setCellHeaderProps: () => ({className: classes.centeredHeader, }),     },
      align: 'center',
    },
 
    {
      name: "Identifiers",
      options: {        setCellHeaderProps: () => ({className: classes.centeredHeader, }),     },
      align: 'center',
    },
    {
      name: "Count of PII",
      options: {        setCellHeaderProps: () => ({className: classes.centeredHeader, }),     },
    },
  ];
 
  const options = {
    selectableRowsHideCheckboxes: true,
    filter: true,
    onFilterChange: (changedColumn, filterList) => {      console.log(changedColumn, filterList);    },
    setRowProps: () => ({className: classes.centeredrow}), 
    selectableRows: "single",
    filterType: "multiselect",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      //DgPage(rowData[0].trim()); 
      //setRowsa(axiosexpands(rowData[0].trim()));
      console.log('expand');
      console.log(rowData, rowMeta);
      console.log((roleData1[rowMeta["dataIndex"]][5]));
        return ( 
          <React.Fragment>
          <tr>
            <td colSpan={6}>
              <TableContainer component={Paper}>
              <Table style={{ minWidth: "650" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                    <TableCell  align="center" >COLUMN NAME</TableCell>
                      <TableCell align="center">PRIVACY CATEGORY</TableCell>
                      <TableCell align="center">SEMANTIC CATEGORY</TableCell>
                      <TableCell align="center">TAG</TableCell>
                      <TableCell align="center">PROBABILITY</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{(roleData1[rowMeta["dataIndex"]][5]).map(row => (
                                    <TableRow key={row["Column Name"]}>
                                    <TableCell  align="center" component="th" scope="row">{row["Column Name"]}</TableCell>
                        <TableCell align="center">{row["Privacy Category"]}</TableCell>
                        <TableCell align="center">{row["Semantic Category"]}</TableCell>
                        <TableCell align="center">{row.T}</TableCell>
                        <TableCell align="center">{row.Probability}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );   
    },
    page: 1
  };
  return (
    <div className="Outertext">
    <div>
      <img src={Background} alt="BG" />
    </div>
    <br></br>
    
    <MUIDataTable
      columns={columns}
      data={roleData1}
      options={options}
    />
    </div>
  );
};
export default ExpandableRowTable;