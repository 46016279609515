import React, { useState, useEffect } from "react";
import { Grid, makeStyles, Button, FormControl } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Select, { components } from "react-select";
import "./PersonaManagement.css";

// ==============================================checking==========================

// =======================================================================

const useStyle = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const styles = {
  multiValue: (base, state) => {
    return { ...base, backgroundColor: "lightblue", borderRadius: "5px" };
  },
  multiValueLabel: (base, state) => {
    return { ...base, fontWeight: "normal", color: "black", paddingRight: 6 };
  },
};

const orderOptions = (values) => {
  if (values) {
    return values
      .filter((v) => !v.isEditable)
      .concat(values.filter((v) => v.isEditable));
  } else {
    return null;
  }
};

export default function Createpersonamodel({
  show,
  handleClose,
  addToCollections,
  propsRole,
  title,
}) {
  const classes = useStyle();

  const [option, setOption] = useState([]);
  const [buttonChange, setButtonChange] = useState(true);

  const Url = process.env.BASE_URL;
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;

  const personaId = (propsRole && propsRole.personaId) || null;
  //=====Preselected Value=====
  const mapSelected = (propsRole && propsRole.approvers) || null;

  const MultiValueRemove = (props) => {
    if (props.data.isEditable == false) {
      return null;
    }
    return <components.MultiValueRemove {...props} />;
  };

  const {
    register,
    control,
    setValue,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: { approvers: orderOptions(mapSelected) } });

  const watchFields = watch(); // you can also target specific fields by their names
 console.log(watchFields, "watchFields")
  const checking = () => {
    if (mapSelected.length == watchFields.approvers.length) {
      setButtonChange(true);
    } else {
      setButtonChange(false);
    }
  };

  const onClose = (str) => {
    reset();
    handleClose(str);
  };

  useEffect(() => {
    axios
      .get(Url + "get-jira-approvers", {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((res) => {
        setOption(res.data.jiraApprovers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    checking();
  }, [watchFields]);

  const onSubmit = (data) => {
    var arr = [];
    var obj = {};

    data &&
      data.approvers.map((ele) => {
        obj = { idJiraUser: ele.idJiraUserApprover || ele.idJiraUser };
        arr.push(obj);
      });

    let postData = {
      idPersona: `${personaId}`,
      idJiraApprovers: arr,
    };

    axios
      .post(Url + `update-persona-jira-approvers`, postData, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((res) => {
        // window.location.reload(false)
      })
      .catch((err) => {
        console.log(err);
      });

    onClose("postButtonClicked");
  };
  
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{title || "Create Approvers"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid item container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={12} md={12}>
                <h6>Application Name</h6>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={1}
                    value={propsRole && propsRole.personaName}
                    style={{ height: "36px", marginBottom: "1rem" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <h6>Application Role</h6>

                <FormControl fullWidth variant="outlined">
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={1}
                    value={propsRole && propsRole.groups[0].serviceRoleName}
                    style={{ height: "36px", marginBottom: "1rem" }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <h6>Approvers</h6>
                <Controller
                  control={control}
                  name="approvers"
                  required={true}
                  render={({
                    field: { value, onBlur, onChange, name, ref },
                  }) => (
                    <Select
                      name="approvers"
                      isMulti
                      ref={ref}
                      styles={styles}
                      defaultValue={value}
                      options={option}
                      getOptionLabel={(option) => option.approverName}
                      getOptionValue={(option) => option.approverName}
                      isClearable={false}
                      onChange={onChange}
                      // onBlur={onBlur}
                      components={{ MultiValueRemove }}
                      closeMenuOnSelect={false}
                    />
                  )}
                />
                {errors.approvers && (
                  <span style={{ color: "red", display: "flex" }}>
                    *Approvers is required
                  </span>
                )}
              </Grid>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="contained"
              color="primary"
              type="reset"
              onClick={onClose}
              className={classes.button}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="Submit"
              className={classes.button}
              disabled={buttonChange}
              style={{ color: buttonChange ? "rgba(0, 0, 0, 0.26)" : "white" }}
            >
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
