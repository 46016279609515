import * as React from "react";
import Box from "@mui/material/Box";
import { DialogActions, Dialog, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Background from "../assets/pcore_image.png";
import "./RumAdminstrator.css";
import axios from "axios";
import RollerShadesIcon from "@mui/icons-material/RollerShades";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useOktaAuth } from "@okta/okta-react";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import RumadminRoleTable from "./RumadminRoleTable";
import RumadminQuerieTable from "./RumadminQuerieTable";
import RumadminCostTable from "./RumAdminCostTable";
import Popper from "@material-ui/core/Popper";
import { Dropdown } from "primereact/dropdown";
import { black } from "colors";
import  SimpleDialog  from "./DeleteModal";


const styles = (theme) => ({
  popper: {
    width: "fit-content",
  },
});

function SelectTextFields(props) {
  const { oktaAuth } = useOktaAuth();
  
  const Email = oktaAuth.authStateManager._authState.idToken.claims.email;

  const { loaded_data } = props;
  const [value, setValue] = React.useState();
  const [role, setRole] = useState([]);
  const [cost, setCost] = useState([]);
  const [loading, setLoading] = useState(false);
  const [revokeValue, setRevokeValue] = useState();
  const [deleteModal, setdeleteModal] = useState(false);

  const [querie, setQuerie] = useState([]);
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;
  const Url = process.env.BASE_URL;
  const [button, setButton] = useState(null);
  let roleData = [];
  const [UserEmail, setUserEmail] = useState([]);


  const PopperMy = function (props) {
    return <Popper {...props} style={styles.popper} placement="bottom-start" />;
  };

  const handleCloseDelete = () => {
    setdeleteModal(false);
  };
  function handleSubmit(e) {
    e.preventDefault();
    setRevokeValue();
    setLoading(true);
    setButton("QuerieButton");

    if (value) {
      axios
        .get(`${Url}get-snowflake-user-query-history?user=${value}`, {
          headers: {
            "rum-svc-acct": Svc,
            "rum-api-key": API_KEY,
          },
        })
        .then((data) => {
          const data1 =
            data.data.userQueries !== null ? data.data.userQueries : [];
          setQuerie(data1);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function handleSubmitRole(e) {
    e.preventDefault();
    setButton("RoleButton");
    setLoading(true);

    const Url = process.env.BASE_URL;
    if (value) {
      setUserEmail(value)      
      axios
        .get(`${Url}get-user-personas?email=${value}`, {
          headers: {
            "rum-svc-acct": Svc,
            "rum-api-key": API_KEY,
          },
        })
        .then((data) => {
          const data1 =
            data.data.userPersonas !== null ? data.data.userPersonas : "Currently No Roles Assigned.";
          if (data1 != 'Currently No Roles Assigned.')
          { 
            data1[0].personas.sort((a, b) => {
              if (a.personaName.toLowerCase() < b.personaName.toLowerCase()) {
                return -1;
              } else if (
                a.personaName.toLowerCase() > b.personaName.toLowerCase()
              ) {
                return 1;
              }
              return 0;
            });
          }
          setRole(data1);
          setLoading(false);        
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log(err);
    }
  }
  function handleSubmitCost(e) {
    e.preventDefault();
    setButton("costButton");
    setRevokeValue();

    setCost("No costAvailable");
  }
  if (typeof role == "string") {
    roleData.push(role);
  } else {
    role &&
      role.map((data) => {
        roleData.push(data);
      });
  }
  const handleSubmitDelete = () => {    
    if (revokeValue) {
      const environment = process.env.ENVIRONMENT;

      let Submit = {
        action: revokeValue?.status=="pending"?"cancel":"revoke",
        personaId: revokeValue && revokeValue.personaId,        
        email: UserEmail,
        emailRequester:Email
      };
      // console.log(‘Submit’, Submit)
      let requestUrl=revokeValue?.status=="pending"?"cancel-persona-request":"revoke-persona"
      axios
      .post(Url + requestUrl, Submit, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        if (data.status == 200) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        alert(err);
        <form>
          <input
            type="button"
            value="Click Me"
            onclick={(window.location.href = "/")}
          />
        </form>;
      });
    }
  };
  return (
    <div className="rumAdministratorPage">
      <div className="grid">
        <div className="Image">
          <img src={Background} className="Imagesrc" alt="BG" />
        </div>
        <div className="FormBox">
          <form className="Formout">
            <Box
              className="FormInn"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "30ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <div className="TextField">User Email</div>

                <div className="primereactDropdown">
                  <Dropdown
                    value={value}
                    filter={true}
                    onChange={(e, value) => {
                      setValue(e.target.value);
                      setButton();
                    }}
                    options={loaded_data.map((opt) => ({
                      label: opt.label,
                      value: opt.id,
                    }))}
                    dropdownIcon={<ArrowDropDownIcon fontSize="medium" />}
                    className="w-full"
                  />
                </div>
              </div>
              <div>
                <div className="TextField">Application</div>
                <div className="primereactDropdown">
                  <Dropdown
                    value={"snowflake"}
                    filter={true}
                    
                    options={[{
                      label: "snowflake",
                      value: "snowflake",
                    }]}
                    dropdownIcon={<ArrowDropDownIcon fontSize="medium" />}
                    className="w-full"
                  />
                </div>
               
              </div>
              <div>
                <Button
                  variant="contained"
                  sx={{ marginTop: "35px" }}
                  startIcon={<RollerShadesIcon />}
                  onClick={handleSubmitRole}
                  disabled={!value}
                  type="submit"
                >
                  view roles
                </Button>
                <br /> <br />
              </div>

              <div>
                <Button
                  variant="contained"
                  sx={{ marginTop: "35px" }}
                  startIcon={<QueryStatsIcon />}
                  onClick={handleSubmit}
                  disabled={!value}
                  type="submit"
                >
                  view querie
                </Button>
                <br /> <br />
              </div>

              <div>
                <Button
                  variant="contained"
                  sx={{ marginTop: "35px" }}
                  startIcon={<CurrencyExchangeIcon />}
                  onClick={handleSubmitCost}
                  disabled={!value}
                  type="submit"
                >
                  view cost
                </Button>
                <br /> <br />
              </div>
              <div>
                <Button
                  variant="contained"
                  sx={{ marginTop: "35px" }}
                  startIcon={<DeleteOutlineOutlinedIcon />}
                  onClick={() => {
                    setdeleteModal(true);
                  }}
                  disabled={!revokeValue}
                  type="button"
                >
                  {revokeValue?.status=="pending"?"Cancel":"Revoke"}
                </Button>
                <br /> <br />
              </div>
            </Box>
          </form>
        </div>

        <div className="tabelParent">
          {button == "RoleButton" ? (
            <RumadminRoleTable
              role={role}
              setRole={setRole}
              revokeProp={setRevokeValue}
              spinner={loading}
            />
          ) : button == "QuerieButton" ? (
            <RumadminQuerieTable roleData={querie} spinner={loading} />
          ) : button == "costButton" ? (
            <RumadminCostTable />
          ) : (
            []
          )}
          {deleteModal && (
            <SimpleDialog
              open={deleteModal}
              actionType={revokeValue?.status=="pending"?"Cancel":"Revoke"}
              onClose={handleCloseDelete}
              handleRevokeSubmit={handleSubmitDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function SimpleDialogss(props) {
  const { onClose, open, handleRevokeSubmit } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} className="deleteDialog">
      <DialogTitle>Are you sure to revoke the role?</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleRevokeSubmit} autoFocus>
          Revoke
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function RumAdminstratorpage() {
  const Url = process.env.BASE_URL;
  const [PersonaNames, setPersonaNames] = useState([]);
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;
  useEffect(() => {
    axios
      .get(Url + "get-okta-users-active", {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((res) => {
        // console.log(res.data,'Response Data okta user')
        var obj = {};
        var array = [];
        let data=res.data
        JSON.parse(data)?.okta_user_emails.map((item, i) => {
          obj = { label: item, id: item };
          array.push(obj);
        });

        setPersonaNames(array);
      })
      .catch((err) => console.log(err));
  }, []);
  return <SelectTextFields loaded_data={PersonaNames} />;
}