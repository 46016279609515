import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import { Checkbox, Collapse } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Background from "../assets/pcore_image.png";
import SimpleDialog from "./DeleteModal";
import "./landingpage.css";
// import Button from '@mui/material/Button';
import { makeStyles } from "@material-ui/core/styles";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import SearchBar from "material-ui-search-bar";

function Row(props) {
  const { row, revokeValue, editPrivValue } = props;

  // const ddo = row;
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selec, setSelec] = React.useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = React.useState(null);

  const handleRowClick = (ele) => {
    //currently disabled all checkbox
    if (["pending"].some((item) => item == ele.status)) {
      setSelectedIdEdit(null);
      setSelectedId(ele.personaId === selectedId?.personaId ? null : ele);
      setSelec(selectedId);
    } else if (
      ele.personaName.startsWith("Snowflake") &&
      ["approved"].some((el) => el == ele.status)
    ) {
      setSelectedId(null);
      setSelectedIdEdit(
        ele.personaId === selectedIdEdit?.personaId ? null : ele
      );
      setSelec(selectedIdEdit);
    } else {
      setSelectedId(null);
    }
  };

  // const handleRowEditClick = (ele) => {
  //   //currently disabled all checkbox
  //   if (["pendind"].some((item) => item == ele.status)) {
  //     setSelectedIdEdit(null);
  //     setSelectedId(ele.personaId === selectedId?.personaId ? null : ele);
  //   } else if (
  //     ele.personaName.startsWith("Snowflake") &&
  //     ["approved"].some((el) => el == ele.status)
  //   ) {
  //     setSelectedId(null);
  //     setSelectedIdEdit(
  //       ele.personaId === selectedIdEdit?.personaId ? null : ele
  //       );
  //     } else {
  //       setSelectedId(null);
  //       setSelectedIdEdit(null);
  //     }
  //   };

  editPrivValue(selectedIdEdit);
  revokeValue(selectedId);
  return (
    <React.Fragment>
      {row !== "Currently No Roles Assigned." ? (
        <>
          {row.personas.length > 0 &&
            row.personas.map((ddo, index) => (
              <React.Fragment key={index}>
                <TableRow
                  sx={{ "& > *": { borderBottom: "unset" } }}
                  hover
                  onClick={() => {
                    handleRowClick(ddo);
                  }}
                  selected={ddo.personaId === selec && selec.personaId}
                >
                  <TableCell>
                    {["pending"].some((ele) => ele == ddo.status) ? (
                      <Checkbox
                        checked={ddo.personaId === selectedId?.personaId}
                      />
                    ) : ddo.personaName?.startsWith("Snowflake") &&
                      ["approved"].some((ele) => ele == ddo.status) ? (
                      <Checkbox
                        checked={ddo.personaId === selectedIdEdit?.personaId}
                      />
                    ) : (
                      <Checkbox disabled />
                    )}
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        setOpen(open === ddo.personaId ? -1 : ddo.personaId)
                      }
                    >
                      {open === ddo.personaId ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>{" "}
                  </TableCell>
                  <TableCell
                    scope="row"
                    style={{
                      fontSize: "larger",
                      fontWeight: "200",
                    }}
                  >
                    <Link href={ddo.jiraUrl} target="_blank">
                      {ddo.rumReference}
                    </Link>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: "larger",
                      fontWeight: "500",
                    }}
                  >
                    {ddo.personaName}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: "larger",
                      fontWeight: "500",
                    }}
                  >
                    {ddo.status}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: "larger",
                      fontWeight: "500",
                    }}
                  >
                    {moment(ddo.expiration_ts).isValid()
                      ? moment(ddo.expiration_ts).format("YYYY-MMM-DD")
                      : null}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: "larger",
                      fontWeight: "500",
                    }}
                  >
                    {moment(ddo.creation_ts).format("YYYY-MMM-DD")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse
                      in={open === ddo.personaId}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ margin: 1 }}>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="center"
                                style={{
                                  color: "#1976d2",

                                  fontSize: "medium",
                                  fontWeight: "500",
                                }}
                              >
                                Application Name
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  color: "#1976d2",

                                  fontSize: "medium",
                                  fontWeight: "500",
                                }}
                              >
                                Application Role Name
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {ddo &&
                              ddo?.groups.map((dd, i) => (
                                <TableRow key={i}>
                                  <TableCell
                                    align="center"
                                    scope="row"
                                    style={{
                                      color: "black",
                                      fontSize: "medium",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {dd.serviceName}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    scope="row"
                                    style={{
                                      color: "black",
                                      fontSize: "medium",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {dd.serviceRoleName}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </>
      ) : (
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell
            align="right"
            style={{
              fontSize: "large",
              fontWeight: "700",
              display: "flex",
              marginLeft: "40px",
            }}
          >
            No Roles Assigned Currently.
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default function CollapsibleTable() {
  const Url = process.env.BASE_URL;
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;
  const { oktaAuth } = useOktaAuth();
  const [roleData, setRoleData] = useState([]);
  const [deleteModal, setdeleteModal] = useState(false);
  const [revokeValue, setRevokeValue] = useState(null);
  const [editPrivValue, setEditPrivValue] = useState(null);
  const [role, setRole] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searched, setSearched] = useState("");
  const [activeButton, setActiveButton] = useState("approved");
  const Email = oktaAuth.authStateManager._authState.idToken.claims.email;

  useEffect(() => {
    if (Email) {
      getData();
    }
  }, [Email]);

  useEffect(() => {
    if (role[0]?.personas) {
      if (activeButton === "all") {
        setFilteredData(role);
      } else {
        const newFilteredData = role[0].personas.filter(
          (item) => item.status === activeButton
        );
        setFilteredData([{ ...role[0], personas: newFilteredData }]);
      }
    }
  }, [activeButton, role]);

  const getData = () => {
    console.log("hello data");
    axios
      .get(`${Url}get-user-personas?email=${Email}`, {
        headers: { "rum-svc-acct": Svc, "rum-api-key": API_KEY },
      })
      .then((data) => {
        const data1 =
          data.data.userPersonas !== null
            ? data.data.userPersonas
            : "Currently No Roles Assigned.";
        setRole(data1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  roleData.length > 0 &&
    roleData[0].personas.sort((a, b) => {
      if (a.personaName.toLowerCase() < b.personaName.toLowerCase()) {
        return -1;
      } else if (a.personaName.toLowerCase() > b.personaName.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  const history = useHistory();

  const handleCloseDelete = () => {
    setdeleteModal(false);
  };

  const handleSubmitDelete = () => {
    if (revokeValue) {
      const environment = process.env.ENVIRONMENT;

      let Submit = {
        action: "cancel",
        personaId: revokeValue && revokeValue.personaId,
        email: Email,
        requester_email: Email,
        status: "pending",
        // expiration: date.toLocaleDateString("sv"),
        env: environment,
      };
      axios
        .post(Url + "submit-request", Submit, {
          headers: {
            "rum-svc-acct": Svc,
            "rum-api-key": API_KEY,
          },
        })
        .then((data) => {
          if (data.status == 200) {
            window.location.href = "/";
          }
        })
        .catch((err) => {
          alert(err);
          <form>
            <input
              type="button"
              value="Click Me"
              onclick={(window.location.href = "/")}
            />
          </form>;
        });
    }
  };

  // const requestSearch = (searchedVal) => {
  //   setSearched(searchedVal);
  //   if (searchedVal.trim() === "") {
  //     console.log("role",role);
  //     setFilteredData(role);
  //   } else {
  //     const filteredData = role.filter((item) =>
  //       item.personaName && item.personaName.toLowerCase().includes(searchedVal.toLowerCase())
  //     );
  //     console.log(filteredData,"filteredData");
  //     setFilteredData(filteredData);
  //   }
  // };
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (searchedVal.trim() === "") {
      setFilteredData(role);
    } else {
      const filteredRoles = role.map((item) => {
        const filteredPersonas = item.personas.filter((persona) =>
          persona.personaName.toLowerCase().includes(searchedVal.toLowerCase())
        );
        return { ...item, personas: filteredPersonas };
      });
      setFilteredData(filteredRoles);
    }
  };
  
  const cancelSearch = () => {
    setSearched("");
    setFilteredData(role);
  };

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <div className="Outertext">
      <div>
        <img src={Background} alt="BG" />
      </div>

      <div
        className="FormBox"
        style={{
          width: "100%",
          marginTop: "5px",
          marginBottom: "-5px",
          borderRadius: "5px",
          height: "70px",
        }}
      >
        <form className="Formout">
          <Box
            className="FormInn"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "30ch" },
              display: "flex",
              justifyContent: "space-between",
            }}
            noValidate
            autoComplete="off"
          >
            <div style={{ display: "flex", marginLeft: "40px" }}>
              <button
                className={
                  activeButton === "approved"
                    ? "active status_button"
                    : "status_button"
                }
                onClick={() => handleClick("approved")}
                type="button"
              >
                Approved
              </button>
              <button
                className={
                  activeButton === "pending"
                    ? "active status_button"
                    : "status_button"
                }
                onClick={() => handleClick("pending")}
                type="button"
              >
                Pending
              </button>
              <button
                className={
                  activeButton === "declined"
                    ? "active status_button"
                    : "status_button"
                }
                onClick={() => handleClick("declined")}
                type="button"
              >
                Declined
              </button>
              <button
                className={
                  activeButton === "revoked"
                    ? "active status_button"
                    : "status_button"
                }
                onClick={() => handleClick("revoked")}
                type="button"
              >
                Revoked
              </button>
              <button
                className={
                  activeButton === "all"
                    ? "active status_button"
                    : "status_button"
                }
                onClick={() => handleClick("all")}
                type="button"
              >
                All
              </button>
            </div>
            <div style={{ marginTop: "12px", marginRight: "20px" }}>
              <SearchBar
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
            </div>
          </Box>
        </form>
      </div>
      <TableContainer
        component={Paper}
        style={{
          border: "solid",
          borderColor: "#ffff",
          borderWidth: "3px",
        }}
      >
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell
                align="center"
                style={{
                  color: "black",
                  fontSize: "large",
                  fontWeight: "700",
                }}
              >
                RUM ID
              </TableCell>
              <TableCell
                align="center"
                style={{
                  color: "black",
                  fontSize: "large",
                  fontWeight: "700",
                }}
              >
                Persona Name
              </TableCell>

              <TableCell
                align="center"
                style={{
                  color: "black",
                  fontSize: "large",
                  fontWeight: "700",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                Status
              </TableCell>
              <TableCell
                align="center"
                style={{
                  color: "black",
                  fontSize: "large",
                  fontWeight: "700",
                }}
              >
                Expiration Date
              </TableCell>
              <TableCell
                align="center"
                style={{
                  color: "black",
                  fontSize: "large",
                  fontWeight: "700",
                }}
              >
                Creation Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData?.length > 0 &&
              filteredData[0].personas != undefined &&
              filteredData?.map((role, i) => (
                <Row
                  key={i}
                  row={role}
                  revokeValue={setRevokeValue}
                  editPrivValue={setEditPrivValue}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="homebutton">
        <div>
          <Button
            variant="contained"
            size="large"
            onClick={() => history.push("/submissionPage")}
          >
            Submit New Request
          </Button>
        </div>
        <div className="positionary">
          <Button
            variant="contained"
            size="large"
            onClick={() => history.push("/PersonaCatalog")}
          >
            Persona Catalog
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            size="large"
            onClick={() => setdeleteModal(true)}
            disabled={!revokeValue}
          >
            Cancel
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            size="large"
            disabled={!editPrivValue}
            onClick={() =>
              history.push({
                pathname: "/editprivileges",
                state: editPrivValue,
              })
            }
          >
            Edit Privileges
          </Button>
        </div>
      </div>
      {deleteModal && (
        <SimpleDialog
          open={deleteModal}
          actionType="Cancel"
          onClose={handleCloseDelete}
          handleRevokeSubmit={handleSubmitDelete}
        />
      )}
    </div>
  );
}
