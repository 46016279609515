// Banner.js
import React, { useState } from 'react';
import './Banner.css';

const Banner = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;  // If banner is not visible, don't render it

  return (
    <div className="banner">
      <p>RUM will be decommissioned by April 19th!Please use the IT service desk to raise access related request.</p>
      <div className="links">
        <div className="link-item">
          <p>Please submit the ticket for Snowflake Access : <a
              href="https://procoretech.atlassian.net/servicedesk/customer/portal/38/group/108/create/2100"
              target="_blank" rel="noopener noreferrer">here</a></p>
        </div>
        <div className="link-item">
          <p>Please submit the ticket for Tableau Access : <a
              href="https://procoretech.atlassian.net/servicedesk/customer/portal/38/group/108/create/1384"
              target="_blank" rel="noopener noreferrer">here</a></p>
        </div>
        <div className="link-item">
          <p>Please submit the ticket for Polaris Access : <a
              href="https://procoretech.atlassian.net/servicedesk/customer/portal/38/create/5566" target="_blank"
              rel="noopener noreferrer">here</a></p>
        </div>
        <div className="link-item">
          <p>Please submit the ticket for AWS Access: <a
              href="https://procoretech.atlassian.net/servicedesk/customer/portal/38/group/108/create/1692"
              target="_blank" rel="noopener noreferrer">here</a></p>
        </div>
        <div className="link-item">
          <p>Please submit the ticket for Databricks Access : <a
              href="https://procoretech.atlassian.net/servicedesk/customer/portal/38/group/108/create/3706"
              target="_blank" rel="noopener noreferrer">here</a></p>
        </div>
        <div className="link-item">
          <p>Please submit the ticket for Databricks catalog Access : <a
              href="https://procoretech.atlassian.net/browse/PDP-22778" target="_blank"
              rel="noopener noreferrer">here</a></p>
        </div>
      </div>
      <button onClick={handleClose} className="close-btn">
        Close
      </button>
    </div>
  );
};

export default Banner;
