import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Grid } from "@material-ui/core/";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import PaymentIcon from "@mui/icons-material/Paid";
import "./Front_Page.css";
import { useHistory } from "react-router-dom";

export default function AuditManagement() {
  const history = useHistory();
  return (
    <Card variant="outlined" sx={{ padding: "2rem 1rem", textAlign: "center" }}>
      <Typography
        variant="h4"
        sx={{
          padding: "10px",
          float: "left",
          color: "black",
          display: "contents",
        }}>
        Audit Management
      </Typography>
      <div><br /></div>
      <Grid
        container
        spacing={5}        
        direction="row"
        justifyContent="center"
        alignItems="center">
        <Grid item xs={6} sm={4} md={3} className="grids">
          <Card className="cardss" onClick={() => history.push("./My_Queries")}>
            <CardActionArea>
              <ContactSupportIcon
                sx={{ fontSize: 60, color: "orange" }}></ContactSupportIcon>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  My Queries
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={6} sm={4} md={3} className="grids">
          <Card className="cardss" onClick={() => history.push("./My_cost")}>
            <CardActionArea>
              <PaymentIcon sx={{ fontSize: 60, color: "orange" }}></PaymentIcon>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  My Cost
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
}
