// ===============================Table=================================

import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Background from "../assets/pcore_image.png";
import "./PersonaCatalog.css";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables() {
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;
  const Url = process.env.BASE_URL;
  const SchemaUrl = process.env.SCHEMA_BASE_URL;

  const [values, setValues] = React.useState();
  const [personaNames, setPersonaNames] = useState([]);
  const [domainValues, setDomainValues] = useState();

  const [catalog, setCatalog] = useState([]);
  const [loading, setLoading] = useState("Select Domains and Persona.");
  const [role, setRole] = useState([]);
  const [domains, setDomains] = useState([]);
  var roleData = [];

  useEffect(() => {
    if (values) {
      setLoading("Loading...")
      axios
        .get(Url + `get-persona-catalog?id_persona=${values}`, {
          headers: {
            "rum-svc-acct": Svc,
            "rum-api-key": API_KEY,
          },
        })
        .then((data) => {
          var array = [];
          const datas = data.data.personaCatalog;
          array.push(datas);
          setCatalog(array);
          setLoading(null);
        })
        .catch((err) => {
          console.log(err);
          setLoading("Data Not Found");
        });
    }
  }, [values]);

  useEffect(() => {
    axios
      .get(Url + "get-persona-domains", {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        setRole(data.data.personaGroups);
        const domain = data.data.domains;

        const OthersSplice = domain
          .splice(domain.indexOf("Others"), 1)
          .toString();
        const sortDomain = [...domain.sort(), OthersSplice];
        setDomains(sortDomain);
      })
      .catch((err) => console.log(err));
  }, []);

  function appFilter(str) {
    setCatalog([]);
    setLoading("Select Persona...");
    const personaFilter = role.filter((ele) => {
      return ele.domains.some((subEle) => {
        return subEle == str;
      });
    });
    personaFilter.sort((a, b) => {
      if (a.personaName.toLowerCase() < b.personaName.toLowerCase()) {
        return -1;
      } else if (a.personaName.toLowerCase() > b.personaName.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setPersonaNames(personaFilter);
  }

  if (typeof role == "string") {
    roleData.push(role);
  } else {
    role &&
      role.map((data) => {
        roleData.push(data);
      });
  }

  const design = {
    border: "solid",
    borderColor: "#ffff",
    borderWidth: "3px",
  };
  const colors = {
    color: "black",
    fontSize: "larger",
    fontWeight: "700",
  };
  const color = {
    verticalAlign: "baseline",
  };
  
  return (
    <div className="personaPage">
      <div className="grid">
        <div className="Image">
          <img style={{ width: "100%" }} src={Background} alt="BG" />
        </div>
        <div className="FormBox" style={{ width: "100%" }}>
          <form className="Formout">
            <Box
              className="FormInn"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "30ch" },
                display: "flex",
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <div className="TextField">Business Unit</div>
                <div className="primereactDropdown">
                  <Dropdown
                    value={domainValues}
                    filter={true}
                    onChange={(e) => {
                      setValues(null);
                      setDomainValues(e.target.value);
                      appFilter(e.target.value);
                    }}
                    options={domains.map((opt) => ({ label: opt, value: opt }))}
                    dropdownIcon={<ArrowDropDownIcon fontSize="medium" />}
                    className="w-full md:w-14rem"
                  />
                </div>
              </div>
              <div>
                <div className="TextField">Persona</div>
                <div className="primereactDropdown">
                  <Dropdown
                    value={values}
                    filter={true}
                    onChange={(e) => {
                      setValues(e.target.value);
                    }}
                    options={personaNames}
                    optionLabel="personaName"
                    optionValue="personaId"
                    dropdownIcon={<ArrowDropDownIcon fontSize="medium" />}
                    className="w-full"
                  />
                </div>
              </div>

        
            </Box>
          </form>
        </div>
        <div className="tabelParent">
          <TableContainer component={Paper} style={design}>
            <Table sx={{ minWidth: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={colors}>Persona</StyledTableCell>
                  <StyledTableCell style={colors}>
                    Application Context
                  </StyledTableCell>
                  <StyledTableCell style={colors}>
                    Approver List
                  </StyledTableCell>
                  <StyledTableCell style={colors}>
                    Access description
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {catalog?.length ? (
                  catalog.map((row, index) => (
                    <StyledTableRow
                      key={row && row.idPersona ? row.idPersona : index}
                    >
                      <StyledTableCell style={color}>
                        {row
                          ? row.personaName
                              ?.split(",")
                              .map((item, index) => (
                                <div key={index}>{item}</div>
                              ))
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell style={color}>
                        {row
                          ? row.serviceType
                              ?.split(",")
                              .map((item, index) => (
                                <div key={index}>{item}</div>
                              ))
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell style={color}>
                        {row
                          ? row.jiraApproverName
                              ?.split(",")
                              .map((item, index) => (
                                <div key={index}>{item}</div>
                              ))
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell style={color}>
                        {row
                          ? row.data.startsWith("{")
                            ? JSON.parse(row.data).roles[0].schemas?.map(
                                (list, index) => (
                                  <div key={index}>
                                      {list.schema_name}
                                  </div>
                                )
                              )
                            : row.data
                          : "-"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell
                      className="novalue"
                      style={{
                        color: "rgb(225, 138, 24)",
                        fontSize: "large",
                        fontWeight: "700",
                        textAlign: "start",
                      }}
                      align="right"
                    >
                      {loading || "No Catalog Found."}
                    </StyledTableCell>

                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
