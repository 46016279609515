import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Icon,
  Image,
  Menu,
  Input,
  Dropdown,
} from "semantic-ui-react";
import "./Navbar.css";
import Logo from "../src/assets/procoe_icon_b.png";
import { useHistory } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

const Navbar = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const login = async () => oktaAuth.signInWithRedirect();
  const logout = async () => oktaAuth.signOut();
  const history = useHistory();
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then(info => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);
  if (!authState) {
    return null;
  }
  return (
    <div>
      <Menu fixed="top" inverted id="font">
        <Container style={{ display: "contents", height: "5px" }}>
          <Menu.Item>
            <div>
              <div>
                <img
                  className="pointer"
                  onClick={() => history.push("/Front_Page")}
                  src={Logo}
                  alt="logo"
                />
              </div>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div>
              <h1 className="RUM">RUM</h1>
            </div>
          </Menu.Item>
          {authState && authState.isAuthenticated && (
            <>
              <HomeIcon
                className="pointer"
                sx={{ fontSize: 40, color: "White", marginTop: "20px" }}
                onClick={() => history.push("/Front_Page")}></HomeIcon>
              {}
            </>
          )}

          {!authState && !authState.isAuthenticated && (
            <Menu.Item onClick={login}>Login</Menu.Item>
          )}

          {authState.isAuthenticated && userInfo && (
            <Menu.Item position="right">
              <div>
                <p>
                  <b>
                    Welcome back,&nbsp;
                    {userInfo.name}!
                  </b>
                </p>
                {/* <div style={{ textAlign: "center" }}>
                  <p>
                    {" "}
                    <b>
                      <a
                        style={{ color: "blue" }}
                        href="mailto:rumsupport@procore.com?body=Please detail you issue">
                        Email for RUM Support...
                      </a>
                    </b>
                  </p>
                </div> */}
              </div>
            </Menu.Item>
          )}
        </Container>
      </Menu>
    </div>
  );
};
export default Navbar;
