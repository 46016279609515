import * as React from "react";
import { Box, Button, Backdrop, CircularProgress } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Dropdown } from "primereact/dropdown";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Background from "../assets/pcore_image.png";
import "./Permissions.css";
import { useForm } from "react-hook-form";

const SelectTextFields = () => {
  const {
    control,
    reset,
    formState: { errors },
  } = useForm();

  const { oktaAuth } = useOktaAuth();
  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState('');
  const [schemaNames, setSchemaNames] = React.useState('');
  const [businessJustification, setBusinessJustification] = React.useState('');
  const [apprnam, setApprname] = React.useState('');
  const [PersonaData, setPersonaData] = React.useState([]);
  const [PersonaName, setPersonaName] = React.useState([]);
  const [DatabaseName, setDatabasename] = React.useState([]); // Database options for dropdown
  const [selectedDatabase, setSelectedDatabase] = React.useState('');
  const [selectedSchema, setSelectedSchema] = React.useState('');
  const [schemaOptions, setSchemaOptions] = React.useState([]);
  const [option, setOption] = React.useState([]);

  const Url = process.env.BASE_URL;
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;

  const userName = location.state?.personaName;

  // Fetch Personas on mount
  React.useEffect(() => {
    axios
      .get(Url + "get-personas", {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        const personas = data.data.personas || [];

        personas.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        setPersonaData(personas);
      })
      .catch((err) => console.log(err));
  }, []);

  // Update Persona options whenever PersonaData changes
  React.useEffect(() => {
    const personaOptions = PersonaData.map((persona) => ({
      label: persona.name,
      value: persona.id,
    }));
    setPersonaName(personaOptions);
  }, [PersonaData]);

  // Fetch Jira Approvers on mount
  React.useEffect(() => {
    axios
      .get(Url + "get-jira-approvers", {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((res) => {
        setOption(res.data.jiraApprovers || []);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Fetch Databases from the flake-get-all-dbs API using POST method
  React.useEffect(() => {
    axios
      .post(Url + "snowflake-get-all-dbs", {}, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((res) => {
        // Map the array of arrays into a proper format for the dropdown
        const databases = res.data.map((dbArray) => ({
          label: dbArray[0],  // Extract the database name from the inner array
          value: dbArray[0],  // Use the database name as the value as well
        }));
        setDatabasename(databases);  // Populate the DatabaseName dropdown options
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Handle Database change and update Schema options accordingly using POST method
  const handleDatabaseChange = (e) => {
    const selectedDatabaseValue = e.value;
    setSelectedDatabase(selectedDatabaseValue);
    setSelectedSchema(''); // Reset schema selection when database changes

    // Fetch Schemas from the snowflake-get-all-schemas API using POST method
    axios
      .post(Url + "snowflake-get-all-schemas", {
        database: selectedDatabaseValue, // Send the selected database name in the request body
      }, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((res) => {
        // Assuming the schema data is structured in the same way as the database response
        const schemas = res.data.map((schemaArray) => ({
          label: schemaArray[0],  // Assuming schema names are inside the inner array
          value: schemaArray[0],  // Using the schema name as the value
        }));
        setSchemaOptions(schemas);  // Update schema dropdown options
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Handle schema selection change
  const handleSchemaChange = (e) => {
    setSelectedSchema(e.value);
    setSchemaNames(e.value); // Store selected schema name for submission
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const Submit = {
      idPersona: values,
      requesterEmail: oktaAuth.authStateManager._authState.idToken.claims.email,
      email: oktaAuth.authStateManager._authState.idToken.claims.email,
      schemas: schemaNames,
      database: selectedDatabase,
    };

    axios
      .post(Url + "submit-persona-change-request-automation", Submit, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        if (data.status === 200) {
          alert("Your request has been successfully submitted.")
          window.location.href = "/";
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <div className="editprivileges">
      <div className="grid">
        <div className="Images">
          <img src={Background} className="Imagesrc" alt="BG" />
        </div>
        <div className="FormBoxs">
          <form className="Formouts" onSubmit={handleSubmit}>
            <Box
              className="fieldbox"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "59ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <h5 style={{ width: "90%", color: "black" }}>
                   Permissions Automation
                <br />
              </h5>
              <br />
              <div className="TextField">Persona required</div>
              <Dropdown
                style={{ height: 40, marginTop: 8 }}
                value={values}
                placeholder={userName}
                onChange={(e) => {
                  setValues(e.value);
                }}
                options={PersonaName}
                optionLabel="label"
                optionValue="value"
                dropdownIcon={<ArrowDropDownIcon fontSize="medium" />}
                className="w-full md:w-30rem"
              />

              <br />

              {/* Database Name Dropdown */}
              <div className="TextField">Database Name</div>
              <Dropdown
                style={{ height: 45, marginTop: 8 }}
                value={selectedDatabase}
                placeholder="Select Database"
                onChange={handleDatabaseChange}
                options={DatabaseName}   // This should now display the database names
                optionLabel="label"      // Display the 'name' of the database
                optionValue="value"      // Use the 'name' as the value
                dropdownIcon={<ArrowDropDownIcon fontSize="medium" />}
                className="w-full md:w-30rem"
              />

              <br />

              {/* Schema Name Dropdown */}
              <div className="TextField">Schema Name</div>
              <Dropdown
                style={{ height: 45, marginTop: 8 }}
                value={selectedSchema}
                placeholder="Select Schema"
                onChange={handleSchemaChange}
                options={schemaOptions}   // This will now be populated dynamically based on the selected database
                optionLabel="label"
                optionValue="value"
                dropdownIcon={<ArrowDropDownIcon fontSize="medium" />}
                className="w-full md:w-30rem"
                disabled={!selectedDatabase} // Disable if no database is selected
              />

              <br />

              {/* Approver Dropdown */}
              <div className="TextField">Approver</div>
              <Dropdown
               style={{ height: 45, marginTop: 8 }}
                  value={apprnam}
                  filter={true}
                  onChange={(e) => {
                    setApprname(e.value); // Fix the state update here
                  }}
                options={option}
                optionLabel="approverName"
                optionValue="idJiraUser"
                dropdownIcon={<ArrowDropDownIcon fontSize="medium" />}
                disabled={false} // Change this condition as needed
                className="w-full md:w-30rem"
              />

              <br />
              <div className="permissions_button">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!selectedDatabase || !schemaNames}
                >
                  Submit
                </Button>
                <br /> <br />
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
};

export default function Permissions() {
  return <SelectTextFields />;
}