import * as React from "react";
import { useHistory } from "react-router-dom";
import { FormControl, Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import Background from "../assets/pcore_image.png";
import "./EditPrivileges.css";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Popper from "@material-ui/core/Popper";
import { Dropdown } from "primereact/dropdown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";
import { black } from "colors";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    "& .MuiInputBase-root": {
      width: "200px", // set the desired width
    },
    "& .MuiFormControl-root": {
      margin: "auto",
    },
    "& .MuiTextField-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
  },
}));

const styles = (theme) => ({
  popper: {
    width: "fit-content",
  },
});

function SelectTextFields() {
  const {
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [open, setOpen] = React.useState(false);
  const { oktaAuth } = useOktaAuth();
  const [values, setValues] = React.useState();
  const [schemaNames, setSchemaNames] = useState("");
  const [businessJustification, setBusinessJustification] = useState("");
  const [aprrover, setaprrover] = useState([]);
  const [PersonaData, setPersonaData] = useState([]);
  const [PersonaName, setPersonaName] = useState([]);
  const [contData, setContData] = useState(null);
  const [apprnam, setApprname] = useState();
  const [auto, setAuto] = useState(true);

  const [option, setOption] = useState([]);
  const [apprenv, setApprenv1] = useState("");
  const Url = process.env.BASE_URL;

  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;

  const history = useHistory();
  var Email = oktaAuth.authStateManager._authState.idToken.claims.email;  
  var UserId = oktaAuth.authStateManager._authState.idToken.claims.idp;

  const classes = useStyles();
  const location = useLocation("");

  const userName = location.state?.personaName;
  const personaOnchange = (id) => {
    PersonaData &&
      PersonaData.map((data) => {
        if (data.id == id) {
          setContData(data);
        }
      });
  };
  const defaultApprover = { approverName: process.env.APPROVER_NAME };
  const defaultOption = option.find(
    (opt) => opt.approverName === defaultApprover.approverName
  );

  const PopperMy = function (props) {
    return <Popper {...props} style={styles.popper} placement="bottom-start" />;
  };
  useEffect(() => {
    setValues(null);
  }, [PersonaName]);
  
  useEffect(() => {
    axios
      .get(Url + "get-personas", {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        const data1 =
          data.data.personas !== null
            ? data.data.personas
            : "Currently No Roles Assigned.";  
        setPersonaData(data1);
      })
      .catch((err) => console.log(err));
    setTimeout(() => {
      //   appFilter("Snow");
      console.log(PersonaName, " Error");
    }, 5000);
  }, []);

  useEffect(() => {
    console.log("defaultOption", defaultOption);
    setApprname(defaultOption && defaultOption.idJiraUser);
  }, [defaultOption]);

  useEffect(() => {
    appFilter("approved");
    personaOnchange(location.state?.id);
    console.log(location.state?.id);
    setValues(location.state?.id);
  }, [PersonaData]);

  useEffect(() => {
    setValues(location.state?.personaId);
    axios
      .get(Url + "get-jira-approvers", {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((res) => {
        setOption(res.data.jiraApprovers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function appFilter(str) {
    setContData(null);
    setAuto(false);
    // const personaFilter = PersonaData.filter((ele) => {
    //   return ele.status;
    // });
    PersonaData.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setPersonaName(PersonaData);
  }
  const init = (opt) => {
    // if (apprenv) {
    const mapList = opt.map((ele) => {
      if (ele.approverName == apprenv) {
        console.log(ele.idJiraUser);
        // setApprname(ele.idJiraUser)
        return {
          label: ele.personaName,
          value: ele.idJiraUser,
          disabled: true,
        };
      }
      return { label: ele.personaName, value: ele.idJiraUser };
    });

    return mapList;
  };

  function ApproversOnchange(e) {
    setaprrover(e);
  }
  function handleSubmit(e) {
    e.preventDefault();
    // setOpen(!open);
    const environment = process.env.ENVIRONMENT;

    let Submit = {
      idPersona: contData?.id,
      requesterEmail: Email,
      email: Email,
      //jiraApprover: apprnam,
      schemas: schemaNames,
      businessUseCase: businessJustification,
    };
    //console.log(Submit, "SUBMIT Data");
    axios
      .post(Url + "submit-persona-change-request", Submit, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        if (data.status == 200) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        alert(err);
        <form>
          <input
            type="button"
            value="Click Me"
            onclick={(window.location.href = "/")}
          />
        </form>;
      });
  }
  return (
    <div className="editprivileges">
      {console.log(values, "valu")}
      <div className="grid">
        <div className="Images">
          <img src={Background} className="Imagesrc" alt="BG" />
        </div>
        <div className="FormBoxs">
          <form className="Formouts">
            <Box
              className="fieldbox"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "59ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <h5 style={{ width: "90%", color: "black" }}>
                {" "}
                If you need any additional access privileges to your existing Persona,
                <br/> Submit the form with all necessary details.
                <br/> ------------------------------------
              </h5>
              <br /> 
              <div>
                <div className="TextField">Your Current Persona Name </div>

                <Dropdown
                  style={{ height: 45, marginTop: 8 }}
                  value={values}
                  // defaultValue={userName}
                  placeholder={userName}
                  // disabled={true}
                  onChange={(e) => {
                    console.log(e.value, "dro");
                    setValues(e.value);
                    personaOnchange(e.value);
                  }}
                  options={PersonaName}
                  optionLabel="name"
                  optionValue="id"
                  dropdownIcon={<ArrowDropDownIcon fontSize="medium" />}
                  className="w-full md:w-30rem"
                />
                {/* <input values={userName}></input> */}
                {/* {console.log(PersonaName, "PersonaName")}{" "} */}
              </div>
              <br /> 
              <div>
                <div className="TextField">Which databases/schemas do you need access to?</div>
                <div>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    name="schemaNames"
                    rows={3}
                    // defaultValue={contData?.personaDescription || ""}
                    value={schemaNames}
                    onChange={(e) => setSchemaNames(e.target.value)}
                    style={{ height: "90px" }}
                  />
                  {errors.schemaNames && (
                    <Form.Text className="text-danger">
                      schemaNames is required
                    </Form.Text>
                  )}
                </div>
              </div>
              <br /> 
              <div>
                <div className="TextField">Business Justification</div>
                <div>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    name="businessJustification"
                    rows={3}
                    //   defaultValue={contData?.personaDescription || ""}
                    value={businessJustification}
                    onChange={(e) => setBusinessJustification(e.target.value)}
                    style={{ height: "90px" }}
                  />
                  {errors.businessJustification && (
                    <Form.Text className="text-danger">
                      businessJustification is required
                    </Form.Text>
                  )}
                </div>
              </div>
              <br /> 
              <div>
                <div className="TextField">Approver</div>
                <Dropdown
                  style={{ height: 45, marginTop: 8 }}
                  value={apprnam}
                  filter={true}
                  onChange={(e) => {
                    setApprname(e.target.value);
                    ApproversOnchange(e.target.value);
                  }}
                  options={option}
                  optionLabel="approverName"
                  optionValue="idJiraUser"
                  dropdownIcon={<ArrowDropDownIcon fontSize="medium" />}
                  disabled={true}
                  className="w-full md:w-30rem"
                />
                <div className="editprivileages_button">
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!businessJustification || !schemaNames}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  <br /> <br />
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={open}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              </div>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
}

export default function EditPrivileges() {
  return <SelectTextFields />;
}
