import * as React from "react";

import "./My_Queries.css";

import Soon from "../assets/soon.png";
export default function BasicTable() {
  return (
    <>
      <div className="Outerbox">
        <img style={{ width: "100%" }} src={Soon}></img>
      </div>
    </>
  );
}
