import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "./landingpage.css";
import Background from "../assets/pcore_image.png";
import { useOktaAuth } from "@okta/okta-react";
import MUIDataTable from "mui-datatables";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';


import Soon from "../assets/soon.png";
export default function BasicTable() {
  return (
    <>
      <div className="Outerbox">
        <img style={{ width: "100%" }} src={Soon}></img>
      </div>
    </>
  );
}