import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Grid } from "@material-ui/core/";
import GroupsIcon from "@mui/icons-material/Groups";
import "./Front_Page.css";
import { useHistory } from "react-router-dom";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from '@mui/icons-material/Edit';
export default function AccessManagement() {
  const history = useHistory();
  return (
    <Card variant="outlined" sx={{ padding: "2rem 1rem", textAlign: "center" }}>
      <Typography
        variant="h4"
        sx={{
          padding: "10px",
          float: "left",
          color: "black",
          display: "contents",
        }}>
        Access Management 
      </Typography>
      <div><br /></div>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center">
        <Grid item xs={2.4} sm={4} md={3}>
          <Card
            className="cardss"
            onClick={() => history.push("./landingpage")}>
            <CardActionArea>
              <GroupsIcon sx={{ fontSize: 60, color: "orange" }}></GroupsIcon>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  My Roles
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={2.4} sm={4} md={3} className="grids">
          <Card
            className="cardss"
            onClick={() => history.push("/submissionPage")}>
            <CardActionArea>
              <PersonAddAlt1Icon
                sx={{ fontSize: 60, color: "orange" }}></PersonAddAlt1Icon>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  New Request
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={2.4} sm={4} md={3} className="grids">
          <Card
            className="cardss"
            onClick={() => history.push("/PersonaCatalog")}>
            <CardActionArea>
              <DescriptionIcon
                sx={{ fontSize: 60, color: "orange" }}></DescriptionIcon>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Persona Catalog
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

          <Grid item xs={2.4} sm={4} md={3} className="grids">
          <Card
            className="cardss"
            onClick={() => history.push("/editprivileges")}>
            <CardActionArea>
              <EditIcon
                sx={{ fontSize: 60, color: "orange" }}></EditIcon>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                 Edit Persona
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={2.4} sm={4} md={3} className="grids">
          <Card
            className="cardss"
            onClick={() => history.push("/EditPersonaExpiration")}>
            <CardActionArea>
              <EditIcon
                sx={{ fontSize: 60, color: "orange" }}></EditIcon>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                 Edit Persona Expiration
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

     <Grid item xs={2.4} sm={4} md={3} className="grids">
          <Card
            className="cardss"
            onClick={() => history.push("/Permissions")}>
            <CardActionArea>
              <EditIcon
                sx={{ fontSize: 60, color: "orange" }}></EditIcon>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                 Permissions
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
}