import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Grid } from "@material-ui/core/";
import "./Front_Page.css";
import { useHistory } from "react-router-dom";

import PersonIcon from "@mui/icons-material/Person";
export default function ManagerAdminView() {
  const history = useHistory();
  return (
    <Card variant="outlined" sx={{ padding: "2rem 1rem", textAlign: "center" }}>
      <Typography
        variant="h4"
        sx={{
          padding: "10px",
          float: "left",
          color: "black",
          display: "contents",
        }}>
        Manager AdminView
      </Typography>
      <div>
        <br />
      </div>
      <Grid
        container
        spacing={5}
        direction="row"
        justifyContent="center"
        alignItems="center">
        <Grid item xs={6} sm={3} md={3} className="grids">
          <Card
            className="cardss"
            onClick={() => history.push("./ManagerAdminViewpage")}>
            <CardActionArea>
              <PersonIcon sx={{ fontSize: 50, color: "orange" }}></PersonIcon>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  User Access Management
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
         {/*<Grid item xs={6} sm={3} md={3} className="grids">
          <Card
            className="cardss"
            onClick={() => history.push("./PersonaManagement")}>
            <CardActionArea>
              <PersonIcon sx={{ fontSize: 60, color: "orange" }}></PersonIcon>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Persona Management
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={6} sm={3} md={3} className="grids">
          <Card
            className="cardss"
            onClick={() => history.push("./DgPage")}>
            <CardActionArea>
              <PersonIcon sx={{ fontSize: 60, color: "orange" }}></PersonIcon>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Data classification
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid> */}
      </Grid>
    </Card>
  );
}
